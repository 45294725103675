import cx from "classnames";
import { Layout } from "components/Layout";
import { graphql, PageProps } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { ReactComponent as ArrowDown } from "icons/arrow-down.svg";
import React, { Fragment, useState } from "react";

import { Disclosure, Listbox, Tab, Transition } from "@headlessui/react";

function StrokeHeading() {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <div className="title-stroke -mb-1.5 text-center text-5xl font-bold uppercase italic lg:text-[155px]">
            FAQ
        </div>
    );
}

const getIndexFromHash = (hash: string) => {
    const index = [
        '#installation',
        '#account',
        '#payment',
        '#game',
        '#miscellaneous',
    ].indexOf(hash);

    return index === -1 ? 0 : index;
};

export default function FAQ({ location, data }: PageProps<Queries.FAQQuery>) {
    const { hash } = location;
    const { t, language } = useI18next();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const tabs = [
        data.installation,
        data.account,
        data.payment,
        data.game,
        data.miscellaneous,
    ];

    React.useEffect(() => {
        setSelectedIndex(getIndexFromHash(hash));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [hash]);

    return (
        <Layout>
            <div className="mb-24 lg:mb-72 lg:grid">
                <img
                    src="/images/heading-aura.svg"
                    alt="Background"
                    className="-z-10 hidden object-none object-top lg:col-span-full  lg:row-start-1 lg:block"
                />
                <div className="lg:col-span-full lg:row-span-full lg:pt-28">
                    <StrokeHeading />
                    <div className="bg-gradient-100 px-4 pb-24 lg:bg-none lg:pb-0">
                        <div className="mx-auto max-w-container">
                            <h1
                                className={cx(
                                    "pt-20 pb-6 text-center text-4xl font-bold lg:pb-16",
                                    {
                                        invisible: t("title.faq") === "FAQ",
                                    }
                                )}
                            >
                                {t("title.faq")}
                            </h1>
                            <Listbox
                                value={selectedIndex}
                                onChange={setSelectedIndex}
                            >
                                <div
                                    className={cx(
                                        language === "ja-JP"
                                            ? "text-lg"
                                            : "text-2xl",
                                        "relative mb-16 lg:hidden"
                                    )}
                                >
                                    <Listbox.Button className="relative h-[54px] w-full cursor-default rounded-lg bg-white">
                                        <span className="block truncate text-center">
                                            {
                                                tabs[selectedIndex]?.frontmatter
                                                    ?.title
                                            }
                                        </span>
                                        <span
                                            className={cx(
                                                language === "ja-JP"
                                                    ? "right-1"
                                                    : "right-4",
                                                "pointer-events-none absolute inset-y-0 flex items-center pr-2"
                                            )}
                                        >
                                            <ArrowDown
                                                width={18}
                                                height={18}
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute mt-1 w-full divide-y divide-divider overflow-auto rounded-[29px] bg-white shadow-lg">
                                            {tabs.map((tab, idx) => (
                                                <Listbox.Option
                                                    key={tab?.id}
                                                    className=""
                                                    value={idx}
                                                >
                                                    <span className="block truncate py-5 text-center">
                                                        {
                                                            tab?.frontmatter
                                                                ?.title
                                                        }
                                                    </span>
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                            <Tab.Group
                                selectedIndex={selectedIndex}
                                onChange={setSelectedIndex}
                            >
                                <Tab.List className="mb-40 hidden justify-between lg:flex">
                                    {tabs.map((tab) => (
                                        <Tab key={tab?.id} as={Fragment}>
                                            {({ selected }) => (
                                                <button
                                                    type="button"
                                                    className={cx(
                                                        "py-2.5 px-6 font-bold",
                                                        {
                                                            "bg-black text-white":
                                                                selected,
                                                        },
                                                        language === "ja-JP"
                                                            ? "text-lg"
                                                            : "text-2xl"
                                                    )}
                                                >
                                                    {tab?.frontmatter?.title}
                                                </button>
                                            )}
                                        </Tab>
                                    ))}
                                </Tab.List>
                                <Tab.Panels className="mx-auto max-w-classic">
                                    {tabs.map((tab) => (
                                        <Tab.Panel
                                            key={tab?.id}
                                            className="border-b-5"
                                        >
                                            {tab?.fields?.questions?.map(
                                                (q) => (
                                                    <Disclosure
                                                        key={q?.question}
                                                    >
                                                        {({ open }) => (
                                                            <>
                                                                <Disclosure.Button className="flex w-full items-center border-t-5 py-5 pr-4 text-left font-bold lg:py-6 lg:text-2xl">
                                                                    <span className="grow">
                                                                        {
                                                                            q?.question
                                                                        }
                                                                    </span>
                                                                    <ArrowDown
                                                                        width={
                                                                            18
                                                                        }
                                                                        height={
                                                                            18
                                                                        }
                                                                        className={cx(
                                                                            "ml-4 shrink-0",
                                                                            {
                                                                                "rotate-180 transform":
                                                                                    open,
                                                                            }
                                                                        )}
                                                                    />
                                                                </Disclosure.Button>
                                                                <Disclosure.Panel
                                                                    className={cx(
                                                                        "prose prose-sm max-w-none pb-5 prose-a:text-purple-200 prose-hr:border-black lg:prose-xl lg:pb-20"
                                                                    )}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            q?.answer ??
                                                                            "",
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Disclosure>
                                                )
                                            )}
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
    fragment FAQNode on MarkdownRemark {
        id
        frontmatter {
            title
        }
        fields {
            questions {
                answer
                question
            }
        }
    }
    query FAQ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ...Translation
                }
            }
        }
        account: markdownRemark(
            frontmatter: { type: { eq: "faq" }, slug: { eq: "Account" } }
            fields: { language: { eq: $language } }
        ) {
            ...FAQNode
        }
        installation: markdownRemark(
            frontmatter: { type: { eq: "faq" }, slug: { eq: "Installation" } }
            fields: { language: { eq: $language } }
        ) {
            ...FAQNode
        }
        game: markdownRemark(
            frontmatter: { type: { eq: "faq" }, slug: { eq: "Game" } }
            fields: { language: { eq: $language } }
        ) {
            ...FAQNode
        }
        payment: markdownRemark(
            frontmatter: { type: { eq: "faq" }, slug: { eq: "Payment" } }
            fields: { language: { eq: $language } }
        ) {
            ...FAQNode
        }
        miscellaneous: markdownRemark(
            frontmatter: { type: { eq: "faq" }, slug: { eq: "Miscellaneous" } }
            fields: { language: { eq: $language } }
        ) {
            ...FAQNode
        }
    }
`;

export { Head } from "components/GCSRewriteURL";
